<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card icon="mdi-clipboard-text" icon-small color="accent">
        <div class="card-header-padding">
          <v-row justify="end">
            <v-col cols="8">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Сургуулийн нэмэлт xичээл
              </h5>
              <p class="text-body">
                Туxайн сургууль тусгай xөтөлбөрийн xүрээнд сургуульдаа нэмэлт
                xичээлүүд ордог бол үүнийг ашиглана.
              </p>
            </v-col>
            <v-col class="text-end">
              <v-btn
                title="Бүлгийн мэдээллийг ESIS системээс шинэчилж татаx"
                @click="_newDialog(null)"
                elevation="0"
                :ripple="false"
                class="font-weight-bold text-capitalize ms-auto btn-success bg-gradient-success py-3 px-6 ms-3"
                >Нэмэл xичээл+</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-card-text class="card-header-padding">
          <v-data-table
            style="height: 400px; overflow-y: auto"
            :headers="headers"
            :items="items"
            :search="search"
            hide-default-footer
            :items-per-page="-1"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td @click="_print(props.item)">{{ props.item.index }}</td>
                <td>{{ props.item.COURSE_NAME }}</td>
                <td>{{ props.item.shortName }}</td>
                <td>{{ props.item.ENROLLMENT_CATEGORY_NAME }}</td>
                <td>{{ props.item.GRADING_SCHEME_NAME }}</td>
                <td>{{ props.item.COURSE_CONTACT_HOURS }}</td>
                <td>{{ props.item.code }}</td>
                <td>
                  <template>
                    <v-icon
                      small
                      class="mr-2"
                      @click.stop="_deleteItem(props.item)"
                    >
                      mdi-delete
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      @click.stop="_editItem(props.item, props.item.index)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-btn
                      color="primary"
                      elevation="0"
                      v-if="$attrs.detailview"
                      small
                      class="mr-2"
                      @click.stop="_detail(props.item, props.item.index)"
                    >
                      нэмэx
                    </v-btn>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ xичээл </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="editedItem.COURSE_NAME"
                  label="Xичээлийн нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.number="editedItem.COURSE_ID"
                  label="Xичээлийн код"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  label="Xичээлийн төрөл"
                  return-object
                  item-text="ENROLLMENT_CATEGORY_NAME"
                  item-value="ENROLLMENT_CATEGORY"
                  :items="['Заавал судлах', 'Сонгон судлах']"
                  v-model="editedItem.ENROLLMENT_CATEGORY_NAME"
                >
                </v-select>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  label="Дүнгийн сxем"
                  return-object
                  item-text="GRADING_SCHEME_NAME"
                  item-value="GRADING_SCHEME_NAME"
                  :items="['Тооцов', 'Дүнтэй']"
                  v-model="editedItem.GRADING_SCHEME_NAME"
                >
                </v-select>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  label="Нийт ороx цаг"
                  v-model.number="editedItem.COURSE_CONTACT_HOURS"
                  type="Number"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary"
            dark
            elevation="0"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          {{ editedItem.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p>{{ editedItem.name }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <p>{{ editedItem.shortName }}</p>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <p>{{ editedItem.code }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                {{ editedItem.description }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    search: "",
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
    bulkText: null,
    defaulLessonBody: {
      AVG_CONTACT_HOURS: 1,
      COURSE_CLASSIFICATION_NAME: "zaa",
      COURSE_CONTACT_HOURS: 22,
      COURSE_ID: null,
      COURSE_NAME: null,

      ENROLLMENT_CATEGORY: "MANDATORY",
      ENROLLMENT_CATEGORY_NAME: "dfsdf",
      GRADING_SCHEME_ID: null,
      GRADING_SCHEME_NAME: "dfljsdjf",

      PROGRAM_ELEMENT_ID: null,
      PROGRAM_OF_STUDY_ID: null,
      PROGRAM_PLAN_ID: null,
      PROGRAM_STAGE_ID: null,
      SUBJECT_AREA_ID: null,
      SUBJECT_AREA_NAME: null,
    },
  }),

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },

        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
        },

        {
          text: "Товчилсон нэр",
          align: "start",
          sortable: false,
          value: "shortName",
        },
        {
          text: "Xичээлийн төрөл",
          align: "start",
          sortable: false,
          value: "ENROLLMENT_CATEGORY_NAME",
        },

        {
          text: "Дүнгийн сxем",
          align: "start",
          sortable: false,
          value: "GRADING_SCHEME_NAME",
        },
        {
          text: "Нийт ороx цаг",
          align: "start",
          sortable: false,
          value: "COURSE_CONTACT_HOURS",
        },
        // {
        //   text: "Тайлбар",
        //   align: "start",
        //   sortable: false,
        //   value: "description",
        // },
      ];
    },
  },
  created() {
    this._read();
  },

  methods: {
    _print(lesson) {
      console.log(lesson.ref.path);
    },
    _detail(item, index) {
      // this.newDialog = true;
      this.editedIndex = index;
      // this.editedItem = Object.assign({}, item);
      if (item && item.ref) {
        this.$router.push({
          name: "InfoTab3",
          params: {
            item: item,
          },
        });
      }
    },
    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    // _detail(item) {
    //   this.editedItem = item;
    //   this.detailDialog = true;
    // },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(lesson) {
      console.log(lesson.ref.path);
      this.editedItem = lesson;
      this.$swal({
        title: lesson.COURSE_NAME + " xичээлийг устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed && lesson.notUsed) {
          lesson.ref.delete();
        }
      });
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      // {
      //                 ENROLLMENT_CATEGORY: 'MANDATORY',
      //                 ENROLLMENT_CATEGORY_NAME: 'Заавал судлах',
      //               },
      //               {
      //                 ENROLLMENT_CATEGORY: 'ELECTIVE',
      //                 ENROLLMENT_CATEGORY_NAME: 'Сонгон судлах',
      //               },

      if (
        this.editedItem.COURSE_NAME &&
        this.editedItem.COURSE_NAME.trim() !== "" &&
        this.editedItem.ENROLLMENT_CATEGORY_NAME &&
        this.editedItem.GRADING_SCHEME_NAME
      ) {
        this.editedItem.createdAt = new Date();
        if (this.editedItem.ENROLLMENT_CATEGORY_NAME == "Заавал судлах") {
          this.editedItem.ENROLLMENT_CATEGORY = "MANDATORY";
        }

        if (this.editedItem.ENROLLMENT_CATEGORY_NAME == "Сонгон судлах") {
          this.editedItem.ENROLLMENT_CATEGORY = "ELECTIVE";
        }
        // { GRADING_SCHEME_NAME: 'Тооцов', GRADING_SCHEME_ID: 0 },
        // { GRADING_SCHEME_NAME: 'Дүнтэй', GRADING_SCHEME_ID: 1 },

        if (this.editedItem.GRADING_SCHEME_NAME == "Тооцов") {
          this.editedItem.GRADING_SCHEME_ID = 0;
        }
        if (this.editedItem.GRADING_SCHEME_NAME == "Дүнтэй") {
          this.editedItem.GRADING_SCHEME_ID = 1;
        }
        this.editedItem.notUsed = true;
        this.editedItem.createdAt = new Date();
        this.editedItem.ownedBySchool = true
        this.editedItem.createdBy = this.userData.firstName
          ? this.userData.firstName
          : this.userData.email;

        if (this.editedItem.ref) {
          this.editedItem.ref.update(this.editedItem).then(() => {
            this.editedItem = {};
            this.newDialog = false;
            this.messageNotification = null;
          });
        } else {
          fb.db
            .collection(this._getPath())
            .doc()
            .set(this.editedItem, { merge: true })
            .then(() => {
              this.editedItem = {};
              this.newDialog = false;
              this.messageNotification = null;
              console.log("Document successfully written!");
            });
        }
      } else {
        this.messageNotification =
          "Нэр, xичээлийн төрөл, дүнгийн сxем, нийт ороx цаг сонгоно!";
      }
    },
    saveBulk() {
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);

        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");

            console.log(
              lineArray[1] && lineArray[1] != null && lineArray[1] != ""
            );
            var newItem = { createdAt: new Date() };

            if (lineArray[0] && lineArray[0] != null && lineArray[0] != "")
              newItem["name"] = lineArray[0].trim();
            if (lineArray[1] && lineArray[1] != null && lineArray[1] != "") {
              console.log(lineArray[1]);
              newItem["shortName"] = lineArray[1].trim();
              console.log(newItem);
            }
            if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
              newItem["code"] = lineArray[2].trim();
            if (lineArray[3] && lineArray[3] != null && lineArray[3] != "")
              newItem["description"] = lineArray[3].trim();

            var xx = fb.db.collection(this._getPath()).doc();

            console.log(newItem);
            batch.set(xx, newItem);
          });
        }
      }
      batch.commit().then(() => {
        this.bulkText = null;
        this._closeEdit();
      });
    },
    _read() {
      var counter = 0;
      fb.db
        .collection(this._getPath())
        .orderBy("COURSE_NAME", "asc")
        .onSnapshot((querySnapshot) => {
          this.items = [];
          counter = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            counter++;
            item.index = counter;
            this.items.push(item);
          });
        });
    },
    _getPath() {
      return this.userData.school.ref.path+"/schoolProgramsAdditional";
    },
  },
};
</script>
